import React, { useState, useEffect } from "react"
import { setCookie, getCookie } from '../assets/js/util'
import styled from "@emotion/styled"

const IntroAnimation = ({introduction}) => {

  // Styled Components

  const Introduction = styled.section`
    animation: fadeOut .5s;
    animation-delay: 4.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background: #fff;
    transition: all 1000ms ease-in-out;
    opacity: 1;
    visibility: visible;
    position: fixed;
    z-index: 10;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
        
    @keyframes fadeOut {
      0% {
        opacity: 1;
        visibility: visible;
      }
    
      100% {
        opacity: 0;
        visibility: hidden;
      }
    }
  `

  const IntroductionTitle = styled.h1`
    animation: scaleInOut 5s;
    color: black;
    font-size: 21px;
    line-height: 34px;
    text-align: center;
    padding: 0 30px;
    max-width: 700px;
    margin: 0 auto;
    -webkit-text-stroke: .5px #000;
    -webkit-text-fill-color: transparent;
    
    @media screen and (min-width: 641px) {
      -webkit-text-stroke: .75px #000;
      font-size: 42px;
      line-height: 64px;
      padding: 0;
    }
  
    @keyframes scaleInOut {
      0% {
        opacity: 0;
      }
      25% {
        opacity: 1;
      }
      50% {
        opacity: 1;
      }
      75% {
        opacity: 1;
        transform: scale(1, 1);
      }
      100% {
        opacity: 0;
        transform: scale(3, 3);
      }
    }
  `

  //// State

  // Cookie
  const [firstLoad, setFirstLoad] = useState(true)

  // Animate out
  const [animationEnd, setAnimationEnd] = useState(true)

  // Set Cookie
  useEffect(() => {
    if (getCookie("firstLoad") === null) {
      setCookie("firstLoad", "false", "0.1")
      setFirstLoad(false)
    }
  }, [])

  return animationEnd && !firstLoad && (
    <Introduction onAnimationEnd={ () => setAnimationEnd(false) }>
      <IntroductionTitle>{introduction}</IntroductionTitle>
    </Introduction>
  )

}

export default IntroAnimation
