import UrlMap from './urlMap.json'

/**
 * This functions looks up the id of a page in the UrlMap file. If it finds a match it returns the url for that page.
 * If no mathc is found, or if the id is undefined, it will link to user to the 404 page.
 *
 * @param {String | Symbol} id unique page id as provided by Contentful
 */
export function linkToId( id ) {
  if (!id || !UrlMap[id]) {
    return "/404/"
  }
  return UrlMap[id]
}

export function setCookie(name, value, days) {
  let expires = ""
  if (days) {
    let date = new Date()
    date.setTime(date.getTime() + (days*24*60*60*1000)) // set expire date in millis
    expires = `; expires=${date.toUTCString()}`
  }
  document.cookie = `${name}=${value || ""}${expires}; path=/`
}

export function getCookie(name) {
  let nameEQ = `${name}=`
  let ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0)===' ') c = c.substr(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substr(nameEQ.length, c.length)
  }
  return null
}

export function eraseCookie(name) {
  document.cookie = `${name}=; Max-Age=-99999999;`
}
