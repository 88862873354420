import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { linkToId } from "../assets/js/util"
import { window } from 'browser-monads'
import Logo from "../../static/images/logo.svg"
import IntroAnimation from "../components/introAnimation"
import SEO from "../components/seo"
import BackgroundImages from "../components/backgroundImages"

const Homepage = ({
   data: {
     contentful
   }
 }) => {

  // Styled Components

  const NavigationContainer = styled.article`
    min-height: 100vh;
    height: 100%;
    display: flex;
    overflow: hidden;
    
    @media (max-width: 640px) {
      flex-direction: column;
      padding: 20px;
    }
    
    @media (min-width: 641px) {
      justify-content: center;
      align-items: center;
    }
  `

  const LogoContainer = styled.header`
    
    svg {
      width: 250px;
    }
    
    .text-freddie,
    .text-manage,
    .text-will {
      transition: fill .5s ease-in;
      fill: #fff;
    }
    
    &:hover {
      .text-freddie,
      .text-manage {
        fill: #008100;
      }
      
      .text-will {
        fill: #fff;
      }
    }
    
    @media (max-width: 640px) {
      text-align: left;
    }
    
    @media (min-width: 641px) {
      svg {
        width: 350px;
      }
    }
    
    @media (min-width: 1281px) {
      svg {
        width: 450px;
      }
    }
  `

  const NavigationItem = styled.li`
    list-style-type: none;
    cursor: pointer;
  `

  const NavigationTitle = styled.a`
    font-size: 1.2em;
    line-height: 1.4;
    transition: all .25s ease-in-out;
    -webkit-text-stroke: .75px #fff;
    -webkit-text-fill-color: transparent;
    
    &:hover {
      color: #fff;
      -webkit-text-fill-color: #fff;
    }
    
    .highlighted & {
      -webkit-text-fill-color: #fff;
    }
    
    @media (max-width: 640px) {
      -webkit-text-stroke: .5px #fff;
    }
    
    @media (min-width: 641px) {
      font-size: 1.6em;
      line-height: 1.8;
    }
  `


  const NavigationLink = styled.a`
    transition: all .25s ease-in;
    margin-left: 10px;
    text-transform: lowercase;
    visibility: hidden;
    
    //@keyframes fadeIn {
    //  0% {
    //    opacity: 0;
    //    visibility: hidden;
    //  }
    //
    //  100% {
    //    opacity: 1;
    //    visibility: visible;
    //  }
    //}
    
    &:hover {
      color: #008100;
    }
    
    .highlighted & {
      //animation: fadeIn 1s;
      visibility: visible;
    }
    
    @media (max-width: 640px) {
      font-size: .8em;
    }
    
    @media (min-width: 641px) {
      font-size: 1em;
    }
  `

  // Create component with Gatsby Link

  const Title = NavigationTitle.withComponent(AniLink)
  const Link = NavigationLink.withComponent(AniLink)

  // State

  const [highlightedItem, setHighlightedItem] = useState(0)
  const [bgImages, setBgImages] = useState(contentful.pages[0].images)

  // Highlight element on hover

  function setActiveItem(images, index) {
    setHighlightedItem(index)
    setBgImages(images)
  }

  // Render

  // console.log(window.width)

  const Navigation = () => (
    <nav>
      <ul>
        {contentful.pages.map((page, index) => {
          if (window.outerWidth >= 641) {
            return (
              <NavigationItem
                key={index}
                onMouseEnter={() => setActiveItem(page.images, index)}
                className={index === highlightedItem ? 'highlighted' : ''}>
                <Title
                  swipe
                  direction="up"
                  top="entry"
                  to={linkToId(page.id)}>
                  {page.title}
                </Title>
                <Link
                  swipe
                  direction="up"
                  top="entry"
                  to={linkToId(page.id)}>
                  Lees Meer
                </Link>
              </NavigationItem>
            )
          } else {
            return (
              <NavigationItem
                key={index}
                onClick={() => setActiveItem(page.images, index)}
                className={index === highlightedItem ? 'highlighted' : ''}>
                <NavigationTitle href="#">
                  {page.title}
                </NavigationTitle>
                <Link
                  swipe
                  direction="up"
                  top="entry"
                  to={linkToId(page.id)}>
                  Lees Meer
                </Link>
              </NavigationItem>
            )
          }
        })}
      </ul>
    </nav>
  )

  return (
    <>
      <SEO title={contentful.title} />
      <IntroAnimation introduction={contentful.introduction}/>
      <NavigationContainer>
        <LogoContainer role="banner">
          <Logo/>
        </LogoContainer>
        <Navigation/>
        <BackgroundImages images={bgImages}/>
      </NavigationContainer>
    </>
  )
}

export const query = graphql`
    query($id: String!)  {
        contentful: contentfulHomepage(id: {eq: $id}) {
            id
            title
            introduction
            pages {
                ... on ContentfulPages {
                    title
                    id
                    contentful_id
                    slug
                    content {
                        json
                    }
                    images {
                        title
                        file {
                            url
                        }
                        sizes(quality: 50) {
                            ...GatsbyContentfulSizes_withWebp
                        }
                        fluid(maxWidth: 500) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                    }
                }
                ... on ContentfulEvents {
                    title
                    id
                    contentful_id
                    slug
                    content {
                        json
                    }
                    images {
                        title
                        file {
                            url
                        }
                        sizes(quality: 50) {
                            ...GatsbyContentfulSizes_withWebp
                        }
                        fluid(maxWidth: 500) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`

export default Homepage
